div.gallery,
div.gallery-portrait {
    border: 1px solid #ccc;
}

div.gallery:hover,
div.gallery-portrait:hover {
    border: 1px solid #777;
}

div.gallery img {
    width: 100%;
    height: 200px;
}

div.gallery-portrait img {
    height: 200px;
    max-width: 100%;
}

div.desc {
    padding: 15px;
    text-align: center;
}

* {
    box-sizing: border-box;
}

.responsive {
    padding: 0 6px;
    float: left;
    width: 24.99999%;
}

.prod-title {
    padding: 20px;
    font-size: 28px;
}

.prod-desc {
    padding: 15px 6px;
    font-size: medium;
    text-align: center;
}

.gallery>div,
.gallery-portrait>div {
    text-align: center;
}


/* breadcrumb */

ul.breadcrumb {
    padding: 10px 16px;
    list-style: none;
    background-color: #eee;
}

ul.breadcrumb li {
    display: inline;
    font-size: 13px;
}

ul.breadcrumb li+li:before {
    padding: 8px;
    color: black;
    font-family: FontAwesome;
    content: "\f105";

}

ul.breadcrumb li a {
    color: #0275d8;
    text-decoration: none;
}

ul.breadcrumb li a:hover {
    color: #01447e;
    text-decoration: underline;
}


@media only screen and (max-width: 700px) {
    .responsive {
        width: 49.99999%;
        margin: 6px 0;
    }
}

@media only screen and (max-width: 500px) {
    .responsive {
        width: 100%;
    }
}

.clearfix:after {
    content: "";
    display: table;
    clear: both;
}

div.desc {
    padding: 15px;
    text-align: center;
}

.img-container {
    width: 100%;
    display: inline-block;
    padding: 25px;
}

.desc {
    font-size: smaller;
}