.cards {
  padding: 4rem;
  background: #fff;
}

h1 {
  text-align: center;
}

.prod-subheader {
  text-align: center;
  font-size: 14px;
  padding: 15px;
}

.cards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.cards__wrapper {
  position: relative;
  margin: 50px 0 45px;
}

.cards__items {
  margin-bottom: 24px;
}

.cards__item {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
}

.cards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  /*
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    */

  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(0, 0, 0, 0.17));
  filter: drop-shadow(0 6px 20px rgba(0, 0, 0, 0.17));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.cards__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 67%;
  overflow: hidden;
}


.fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
}

/*
  .cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: #1f98f4;
    box-sizing: border-box;
  }
*/

.cards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.cards__item__img:hover {
  transform: scale(1.1);
}

.cards__item__info {
  padding: 20px 30px 30px;
  text-align: center;
}

.cards__item__text {
  color: #252e48;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
}

.violator-content {
  text-align: center;
  margin: 0 15px;
}

.violator {
  position: static;
  font-size: 12px;
  padding: 5px 10px;
  color: #fa243c;
  border-color: #fa243c;
  margin-top: 19px;
  margin-bottom: 7px;

  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  white-space: nowrap;
  display: inline-block;
  border: 1px solid;
  background: transparent;
  border-color: #bf4800;
  color: #bf4800;
  font-size: 17px;
  line-height: 1.17648;
  font-weight: 400;
  letter-spacing: -.022em;
  font-family: "SF Pro Text", "SF Pro Icons", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  border-radius: 18px;
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 7px;
  padding-bottom: 7px;
  text-align: center;
}

.cards-desc {
  font-size: smaller;
  text-align: center;
  text-decoration: none;
  color: black;
  height: 80px;
  margin: 14px;
}

.viewmore {
  background-color: #d70e12;
  color: white;
  padding: 9px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  margin: 10px;
  font-size: small;
}

/* Contact Us */
.cards__item__link-contact-us {
  display: flex;
  flex-flow: column;
  /* width: 100%; */
  box-shadow: 0 6px 20px rgb(0 0 0 / 17%);
  filter: drop-shadow(0 6px 20px rgba(0, 0, 0, 0.17));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
  margin: 10px;
}

.cards__item__pic-wrap-contact-us {
  position: absolute;
  width: 100%;
  padding-top: 67%;
  overflow: hidden;
}

@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  .cards {
    padding: 4rem 1rem;
  }

  .cards__items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {

  .cards {
    padding: 4rem 1rem;
  }

  .cards__item {
    margin-bottom: 2rem;
  }
}