video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}

.hero-container {
    background: url('../assets/images/Roller Conveyor.jpg') center center/cover no-repeat;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
    animation: breath 4s linear infinite;

}


@keyframes breath {
    0% {
        background-size: 100% auto;
    }

    50% {
        background-size: 120% auto;
    }

    100% {
        background-size: 100% auto;
    }
}

.hero-container>h1 {
    color: #fff;
    /* font-size: 100px; */
    font-size: 68px;
    margin-top: -100px;
    padding: 20px;
    font-family: 'Montserrat';
}

.hero-container>p {
    margin: 8px 70px;
    color: #fff;
    font-size: 18px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
        'Lucida Sans', Arial, sans-serif;
    text-align: center;
    padding: 20px 12px;
    opacity: 0.6;
    background-color: black;
}

.hero-btns {
    margin-top: 32px;
}

.hero-btns .btn {
    margin: 6px;
}

.fa-play-circle {
    margin-left: 4px;
}

@media screen and (max-width: 960px) {
    .hero-container>h1 {
        font-size: 70px;
        margin-top: -150px;
    }

    .hero-container>p {
        margin: 8px 28px;
    }

    .hero-container {
        animation: none;
    }
}

@media screen and (max-width: 768px) {
    .hero-container>h1 {
        font-size: 50px;
        margin-top: -100px;
    }

    .hero-container>p {
        font-size: 15px;
        margin: 8px 30px;
    }

    .btn-mobile {
        display: block;
        text-decoration: none;
    }

    .btn {
        width: 100%;
    }

    .hero-container {
        animation: none;
    }
}