#logo {
    width: 50px;
}

.whats-app {
    position: fixed;
    width: 50px;
    height: 50px;
    bottom: 85px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    left: 15px;
    z-index: 100;
}

.my-float {
    margin-top: 10px;
}

.whatsapp-info {
    z-index: -1;
    /* margin-top: 11px; */
    top: 15px;
    /* height: 36px; */
    left: 40px;
    padding: 5px;
    font-size: 9px;
    background-color: white;
    color: black;
    border-radius: 5px;
    /* left: 0; */
    /* top: 0; */
    position: absolute;
}